<template>
  <div class="requeriments-step">
    <div class="tway-header-2 mb-4">
      Requisitos técnicos
      <v-tooltip bottom max-width="20em">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
        </template>
        <span
          >Agregar archivo de especificación de componentes técnicos, ya sean versionamiento, materiales, fuente de
          información, instrumentos, entre otros.</span
        >
      </v-tooltip>
    </div>
    <v-file-input
      append-icon="mdi-paperclip"
      :prepend-icon="null"
      :rules="[() => (formData.technicalRequirements ? true : 'Campo Requisitos técnicos es requerido')]"
      accept="application/pdf"
      class="mb-10 roboto"
      outlined
      color="rgb(150, 82, 223)"
      placeholder="Agrega tu archivo aquí"
      :value="formData.technicalRequirements"
      @change="uploadRequeriments"
    />
    <div class="tway-header-2 mb-4">
      Anexos (opcional)
      <v-tooltip bottom max-width="20em">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
        </template>
        <span
          >Agregar archivo de especificación de componentes técnicos, ya sean versionamiento, materiales, fuente de
          información, instrumentos, entre otros.</span
        >
      </v-tooltip>
    </div>
    <v-file-input
      append-icon="mdi-paperclip"
      :prepend-icon="null"
      class="mb-10 roboto"
      outlined
      color="rgb(150, 82, 223)"
      placeholder="Agrega tu archivo aquí"
      accept="application/pdf"
      :value="formData.aditionalAttachment"
      @change="uploadAnexed"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidatorsService from "@/services/validatorsService";

export default {
  name: "Requeriments",
  mounted() {
    if (this.stepIsComplete(this.stepIndex)) {
      let stepData = this.getStep(this.stepIndex);
      this.formData = { ...stepData.data };
    } else {
      this.setInProgress(this.stepIndex);
    }
  },
  data() {
    return {
      stepIndex: 2,
      formData: {
        technicalRequirements: null,
        aditionalAttachment: null,
      },
    };
  },
  methods: {
    ...mapActions("createProject", ["setInProgress", "setComplete", "setIncomplete", "setStepData", "resetStep"]),
    uploadRequeriments(file) {
      this.formData.technicalRequirements = file;
      if (file) {
        this.formData.technicalRequirements.changed = true;
      }
      this.fileUpdated();
    },
    uploadAnexed(file) {
      this.formData.aditionalAttachment = file;
      if (file) {
        this.formData.aditionalAttachment.changed = true;
      }
      this.fileUpdated();
    },
    fileUpdated() {
      if (this.formData.technicalRequirements || this.formData.aditionalAttachment) {
        this.setComplete(this.stepIndex);
        this.setStepData({
          index: this.stepIndex,
          data: { ...this.formData },
        });
      } else {
        this.setInProgress(this.stepIndex);
      }
    },
    required: (propertyType) => ValidatorsService.required(propertyType),
  },
  computed: {
    ...mapGetters({
      stepIsComplete: "createProject/getStepIsComplete",
      getStep: "createProject/getStep",
    }),
  },
};
</script>

<style scoped>
.requeriments-step .v-application .primary--text {
  caret-color: #7319d5;
  color: #7319d5 !important;
}
.requeriments-step >>> .v-input__icon {
  transform: rotate(-30deg) !important;
}
</style>
